import { Component, Vue } from "vue-property-decorator";

@Component
export default class Impressum extends Vue {
  public revokeGoogleMaps(): void {
    this.$store.commit("revokewMap");
  }

  get allowGoogleMaps(): boolean {
    return this.$store.getters.allowGoogleMaps;
  }
}
